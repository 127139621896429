import csvConverter from "./csvConverter";
import csvDownload from "./csvDownload";

const handleCsvDownload = (vins) => {
  const headers = ['VIN'];
  if (vins && vins.data) { 
    const data = csvConverter(headers, vins.data);
    csvDownload(data, "fileName");
  }
};

export default handleCsvDownload;