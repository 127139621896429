/**
 * Extract a substring, from a string, between two substrings.
 * Example: extractString(f'foo', f', ') returns foo (without f' neither last ')
 * @param {string} startingString
 * @param {string} startChars
 * @param {string} endChars
 * @returns Substring
 */
const extractString = (startingString, startChars, endChars) => {
  // Get first occurence of startCharts
  const startIndex = startingString.indexOf(startChars);
  // Move cursor at the end of first occurence
  const startIndexAfterStartChars = startIndex + startChars.length;
  // Get LAST occurence of endChars
  const endIndex = startingString.lastIndexOf(endChars);

  return startingString.substring(startIndexAfterStartChars, endIndex);
};

export default extractString;