import React from "react";
import { Button, Modal, ModalBody } from "react-bootstrap";

export function ModalWindow(props) {
  const { show, handleHide, children } = props;
  return (
    <Modal show={show} size="lg" onClick={handleHide}>
      <Modal.Header closeButton >
        <Modal.Title>Dettaglio</Modal.Title>
      </Modal.Header>
      <ModalBody>{children}</ModalBody>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleHide}>
          Chiudi
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalWindow;