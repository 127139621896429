import {
  LOCALSTORAGE_ACCESS_TOKEN,
  LOCALSTORAGE_ID_TOKEN
} from "../constants/constant";

const prepareHeaders = (headers) => {
  if (localStorage.getItem(LOCALSTORAGE_ACCESS_TOKEN)) {
    headers.set(
      "accesstoken",
      `${localStorage.getItem(LOCALSTORAGE_ACCESS_TOKEN)}`
    );
  }
  if (localStorage.getItem(LOCALSTORAGE_ID_TOKEN)) {
    headers.set(
      "authorization",
      `${localStorage.getItem(LOCALSTORAGE_ID_TOKEN)}`
    );
  }
  return headers;
};

export default prepareHeaders;
