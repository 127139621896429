import { createListenerMiddleware } from "@reduxjs/toolkit";
import {
  ACTION_SET_ID_TOKEN,
  LOCALSTORAGE_ID_TOKEN
} from "../utils/constants/constant";

/**
 * When we receive the token after login,
 * store it into localStorage
 */

const type = `accessTokenSlice/${ACTION_SET_ID_TOKEN}`;

const addIdTokenMiddleware = createListenerMiddleware();
addIdTokenMiddleware.startListening({
  type,
  effect: (action) => {
    localStorage.setItem(
      LOCALSTORAGE_ID_TOKEN,
      action.payload.data
    );
  }
});

export default addIdTokenMiddleware;
