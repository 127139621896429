import { ENTITY_BATCHES, ENTITY_SEARCH } from "../constants/constant";

/**
 * Translate the title
 * @param {string} originalTitle
 * @returns string
 */
const titleTranslate = (originalTitle) => {
  if (originalTitle === ENTITY_BATCHES) {
    return "processi";
  }
  if (originalTitle === ENTITY_SEARCH) {
    return "risultati";
  }
  return originalTitle;
};

export default titleTranslate;