import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { useGetDownloadMutation } from "../features/apiSlice";
import { ModalWindow } from "./ModalWindow";
import { PdfObject } from "./PdfObject";
import extractString from "../utils/methods/extractString";

export function Download({ uid }) {

  const [getDownload, {isLoading}] = useGetDownloadMutation();

  const [show, setShow] = useState(false);
  const [pdf, setPdf] = useState(null);

  const handleClick = async () => {
    const result = await getDownload(uid);
    setPdf(extractString(result.data.base64data, "b'", "'"));
    setShow(true);
  };

  const handleHide = () => {
    setShow(false);
  };

  return (
    <>
      <Button onClick={handleClick} disabled={isLoading}>
        <FontAwesomeIcon icon={faDownload} />
      </Button>
      <ModalWindow show={show} handleHide={handleHide}>
        <PdfObject pdf={pdf} />
      </ModalWindow>
    </>
  );
}

export default Download;
