import React from "react";

export function PdfObject({ pdf }) {
  return (
    <object
      data={`data:application/pdf;base64,${pdf}`}
      title="PDF vin"
      type="application/pdf"
      name="Prova"
      width="100%"
      height="500px"
    />
  );
}

export default PdfObject;
