export const ENTITY_BATCHES = "batches";
export const ENTITY_SEARCH = "search";

export const PATH_HOME = "/";
export const PATH_DASHBOARD = "/dashboard";
export const PATH_BATCHES = "/batches";
export const PATH_SEARCH = "/search";
export const PATH_SUPPORT = "/support";

export const LOCALSTORAGE_ACCESS_TOKEN = process.env.REACT_APP_LOCALSTORAGE_ACCESS_TOKEN;
export const LOCALSTORAGE_ID_TOKEN = process.env.REACT_APP_LOCALSTORAGE_ID_TOKEN;

export const ACTION_SET_ACCESS_TOKEN = "setAccessToken";
export const ACTION_SET_ID_TOKEN = "setIdToken";
export const ACTION_SET_CONFIGURATION = "setConfiguration";
export const ACTION_SET_USERNAME = "setUsername";
export const ACTION_SET_LOADING = "setLoading";
export const ACTION_LOGOUT = "logout";

export const TYPE_PRIVATE = "private";
export const TYPE_GUEST = "guest";

export const LOADING = "loading";