import React from "react";
import { Accordion, Col, Container, Nav, Row } from "react-bootstrap";
import { useGetDatasQuery } from "../../features/apiSlice";

export function SupportPage({titlePage}) {

  document.title = titlePage;

  // Fetch the data from store / call
  const { data: configuration } = useGetDatasQuery();

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>Assistenza</h1>
          <p>La conservazione è relativa ai COC immatricolati a partire da {configuration?.dataInizioContratto ?? "N.D."}, secondo le disposizioni contrattuali tra AZH e {configuration?.nomeAzienda ?? "N.D."}.</p>
          <p>Per qualsiasi dubbio od esigenza</p>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Assistenza</Accordion.Header>
              <Accordion.Body>
                <Nav.Link href="https://azhservizi.atlassian.net/servicedesk/customer/portal/5/group/5/create/10023" target="_blanck">
                  <h2>Supporto Tecnico</h2>
                  <p>Hai bisogno di assistenza tecnica? Puoi richiederla qui</p>
                </Nav.Link>
                <Nav.Link href="https://azhservizi.atlassian.net/servicedesk/customer/portal/5/group/5/create/10024" target="_blanck">
                  <h2>Domande su costi e fatturazioni</h2>
                  <p>Scegli questa opzione se hai domande su costi o fatturazione</p>
                </Nav.Link>
                <Nav.Link href="https://azhservizi.atlassian.net/servicedesk/customer/portal/5/group/5/create/10025" target="_blanck">
                  <h2>Segnala un bug</h2>
                  <p>Dicci che problemi stai incontrando</p>
                </Nav.Link>
                <Nav.Link href="https://azhservizi.atlassian.net/servicedesk/customer/portal/5/group/5/create/10026" target="_blanck">
                  <h2>Suggerisci una nuova funzione o miglioramento</h2>
                  <p>Comunicaci la tua idea per una nuova funzione o un miglioramento</p>
                </Nav.Link>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Faq</Accordion.Header>
              <Accordion.Body>
                <h2>Work in progress</h2>
                <p>Presto disponibili</p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Link utili</Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>
                    <Nav.Link href="https://www.agid.gov.it/sites/default/files/repository_files/manuale_di_conservazione_v._5.3.pdf" target="_blanck">Manuale di conservazione</Nav.Link>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
}

export default SupportPage;
