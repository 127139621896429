import React from "react";
import { GetYearInterval } from "@sineverba/years-interval";
import { Col, Container, Image, Row } from "react-bootstrap";
import logo from "../assets/images/logo-azh.png";

export function Footer() {
  return (
    <footer className="sticky-footer">
      <Container fluid>
        <Row>
          <Col>
            <p>
              &copy; {GetYearInterval("2020")} - Frontend CoC by AZ Holding
              S.r.l. - P.IVA 05398841006 - V. {process.env.REACT_APP_VERSION}
            </p>
          </Col>
          <Col>
            <Image src={logo}/>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
