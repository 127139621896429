import React, { memo, useEffect, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

export function ChartComponent(props) {
  /**
   * Fix missing accessibility.enabled false
   */
  const startingOptions = {
    accessibility: {
      enabled: false
    }
  };

  const { options, callback } = props;
  const [currentOptions, setCurrentOptions] = useState(startingOptions);

  /**
   * Deny multiple re-draw
   */
  useEffect(() => {
    if (JSON.stringify(options) !== JSON.stringify(currentOptions)) {
      setCurrentOptions(options);
    }
  }, [currentOptions, options]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={currentOptions}
      immutable
      callback={callback}
    />
  );
}

export default memo(ChartComponent);
