import moment from "moment-timezone";

const vinConverter = (item) => {
  // Destrutturo l'oggetto in arrivo
  const { dataConservazione, dataVersamento } = item;

  const formatData = (data) => moment(data).tz("Europe/Rome").format("DD/MM/YYYY HH:mm");

  // Preparo un oggetto temporaneo
  const tempItem = {
    ...item,
    dataConservazione: formatData(dataConservazione),
    dataVersamento: formatData(dataVersamento)
  };

  return [tempItem];
};

export default vinConverter;
