import {
  faCalendar,
  faGaugeHigh,
  faLifeRing,
  faMagnifyingGlass
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { uniqueKeys } from "@sineverba/unique-keys";
import React from "react";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import {
  PATH_BATCHES,
  PATH_DASHBOARD,
  PATH_SEARCH,
  PATH_SUPPORT
} from "../utils/constants/constant";

export function Menu() {
  const menu = [
    {
      path: PATH_DASHBOARD,
      name: "Dashboard",
      icon: faGaugeHigh
    },
    {
      path: PATH_BATCHES,
      name: "Processi",
      icon: faCalendar
    },
    {
      path: PATH_SEARCH,
      name: "Cerca COC",
      icon: faMagnifyingGlass
    },
    {
      path: PATH_SUPPORT,
      name: "Assistenza",
      icon: faLifeRing
    },
  ];

  // Create same object with unique key
  const menuToMap = uniqueKeys(menu);

  return (
    <>
      <hr className="menu-divider" />
      <ul>
        {menuToMap.map((item) => (
          <li key={item.uniqueKey}>
            <LinkContainer activeClassName="active" to={item.path}>
              <Nav.Link>
                <FontAwesomeIcon icon={item.icon} />
                {item.name}
              </Nav.Link>
            </LinkContainer>
          </li>
        ))}
      </ul>
      <hr className="menu-divider" />
    </>
  );
}

export default Menu;
