import { configureStore } from "@reduxjs/toolkit";
import { accessTokenSlice } from "../features/accessTokenSlice";
import { userSlice } from "../features/userSlice";
import addAccessTokenMiddleware from "../middlewares/addAccessTokenMiddleware";
import addIdTokenMiddleware from "../middlewares/addIdTokenMiddleware";
import removeAccessTokenMiddleware from "../middlewares/removeAccessTokenMiddleware";
import { apiSlice } from "../features/apiSlice";

const setupStore = (preloadedState) => configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    accessTokenSlice: accessTokenSlice.reducer,
    userSlice: userSlice.reducer
  },
  preloadedState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false
  })
    .prepend(addAccessTokenMiddleware.middleware)
    .prepend(addIdTokenMiddleware.middleware)
    .prepend(removeAccessTokenMiddleware.middleware)
    .concat(apiSlice.middleware)
});

export default setupStore;
