import React, { useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FormComponent } from "../../components/FormComponent";
import hasFormErrors from "../../utils/methods/hasFormErrors";
import {
  extractTokenFromPromisedAnswerResult,
  loginCognitoUser
} from "../../utils/methods/cognito";
import loginCognito from "../../utils/calls/aws";
import {
  ACTION_SET_ACCESS_TOKEN,
  ACTION_SET_ID_TOKEN,
  ACTION_SET_LOADING,
  ACTION_SET_USERNAME
} from "../../utils/constants/constant";
import getRequired from "../../utils/methods/getRequired";
import { Loading } from "../../components/Loading";

export function LoginPage({titlePage}) {

  document.title = titlePage;
  /**
   * Local state to save the form
   */
  const [currentItem, setCurrentItem] = useState(null);
  const [disabledForm, setDisabledForm] = useState(true);
  const [error, setError] = useState(false);

  const dispatch = useDispatch();

  // Get loading state
  const loading = useSelector((state) => state.accessTokenSlice.loading);

  const fields = [
    {
      id: 1,
      name: "username",
      type: "text",
      required: true
    },
    {
      id: 2,
      name: "password",
      type: "password",
      required: true
    },
  ];

  const handleChange = (e) => {
    // Destrutturo l'oggetto in arrivo
    const { id, value } = e.target;
    // Preparo un oggetto temporaneo
    const tempItem = {
      ...currentItem,
      [id]: value
    };
    // Lo salvo
    setCurrentItem(tempItem);

    // Prendo solo i campi obbligatori
    const required = getRequired(fields);
    /**
     * Passo alla funzione l'oggetto temporaneo e i campi obbligatori
     */
    if (!hasFormErrors(tempItem, required)) {
      setDisabledForm(false);
    }
  };

  const handleClick = async () => {
    // Start loading
    const loadingAction = {
      type: `accessTokenSlice/${ACTION_SET_LOADING}`,
      payload: {
        data: true
      }
    };
    dispatch(loadingAction);
    // Fetch the data from cognito
    const { cognitoUser, authenticationDetails } =
      loginCognitoUser(currentItem);


    try {
      const data = await loginCognito(cognitoUser, authenticationDetails);
      // Extract the token
      const accessToken = extractTokenFromPromisedAnswerResult(
        data,
        "accessToken"
      );
      if (accessToken) {
        const action = {
          type: `accessTokenSlice/${ACTION_SET_ACCESS_TOKEN}`,
          payload: {
            data: accessToken
          }
        };
        dispatch(action);
      }
      const idToken = extractTokenFromPromisedAnswerResult(data, "idToken");
      if (idToken) {
        const action = {
          type: `accessTokenSlice/${ACTION_SET_ID_TOKEN}`,
          payload: {
            data: idToken
          }
        };
        dispatch(action);
      }

      // Save the username
      const { username } = authenticationDetails;
      const usernameAction = {
        type: `userSlice/${ACTION_SET_USERNAME}`,
        payload: {
          data: username
        }
      };
      dispatch(usernameAction);
    } catch (e) {
      setError(true);
    }
    /**
     * FINALLY, STOP LOADING
     */
    // Stop loading
    const loadingActionStop = {
      type: `accessTokenSlice/${ACTION_SET_LOADING}`,
      payload: {
        data: false
      }
    };
    dispatch(loadingActionStop);
  };

  return (
    <Form className="form-login">
      <Container>
        <Row>
          <Col md={6} lg={4}>
            <Row>
              {fields.map((item) => (
                <Col key={item.id} lg={12}>
                  <FormComponent
                    field={item}
                    onChange={handleChange}
                    currentItem={currentItem}
                  />
                </Col>
              ))}
              <Col lg={12}>
                {loading ? (
                  <Loading />
                ) : (
                  <div className="d-grid gap-2">
                    <Button variant="primary" size="lg" onClick={handleClick} disabled={disabledForm}>
                      Login
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
            {
              error && <Alert variant="danger">Si è verificato un errore</Alert>
            }
          </Col>
        </Row>
      </Container>
    </Form>
  );
}

export default LoginPage;
