import React from "react";
import { Image, Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useGetDatasQuery } from "../features/apiSlice";
import { PATH_HOME } from "../utils/constants/constant";
import extractString from "../utils/methods/extractString";
import { Menu } from "./Menu";

export function Sidebar() {
  // Fetch the data from store / call
  const { data: configuration } = useGetDatasQuery();

  // Use the primary color
  const style = {
    backgroundColor: configuration?.primaryColor ?? "#FFFFFF"
  };

  return (
    <div style={style} className="sidebar menu menu-dark accordion d-block">
      {configuration && configuration.logo && (
        <Navbar.Brand>
          <LinkContainer activeClassName="active" to={PATH_HOME}>
            <Nav.Link>
              <Image fluid src={`data:image/png;base64,${extractString(configuration.logo, "b'", "'")}`} />
            </Nav.Link>
          </LinkContainer>
        </Navbar.Brand>      
      )}
      <Menu />
    </div>
  );
}

export default Sidebar;
