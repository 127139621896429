import React, { useEffect, useState } from "react";
import { fromIsoToHuman } from "@sineverba/date-convert";
import { Col, Container, Row } from "react-bootstrap";
import {
  faCheck,
  faDownload,
  faFilePdf,
  faSpinner
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useGetDetailQuery,
  useGetSummaryQuery,
  useGetVinwococMutation
} from "../../features/apiSlice";
import { LOADING } from "../../utils/constants/constant";
import { ChartComponent } from "../../components/ChartComponent";
import { Kpi } from "../../components/Kpi";
import { Loading } from "../../components/Loading";
import handleCsvDownload from "../../utils/methods/handleCsvDownload";

export function DashboardPage({titlePage}) {

  document.title = titlePage;

  // Fetch the data from store / call
  const { data: detail } = useGetDetailQuery();
  const { data: summary, isFetching: isFetchingSummary } = useGetSummaryQuery();
  const [getVinwococ, { isLoading }] = useGetVinwococMutation();

  /**
   * Initial chart options, with "loading" enabled
   */
  const chartOptions = {
    accessibility: {
      enabled: false
    },
    chart: {
      type: "column",
      events: {
        load() {
          const chart = this;
          chart.showLoading(`${LOADING}...`);
        }
      }
    },
    title: {
      text: "Immatricolato / COC inviati"
    },
    yAxis: {
      min: 0,
      stackLabels: {
        enabled: true,
        formatter() {
          // eslint-disable-next-line
          return `Tot immatricolato: ${this.total}`;
        }
      }
    },
    plotOptions: {
      column: {
        stacking: "normal"
      }
    }
  };

  const [options, setOptions] = useState(chartOptions);

  useEffect(() => {
    if (detail) {
      setOptions({
        ...chartOptions,
        chart: {
          type: "column"
        },
        xAxis: {
          categories: detail.map(
            (item) =>
              `${item.data_em.substring(4, 6)}/${item.data_em.substring(0, 4)}`
          )
        },
        series: [
          {
            name: "Telai Conservati",
            color: "#69BC66",
            data: detail.map((item) => item.ricevuti)
          },
          {
            name: "Telai senza COC",
            color: "#DC3545",
            data: detail.map((item) => item.mancanti)
          },
        ]
      });
    }
  }, [detail]);

  const handleClick = async () => {
    if (!isLoading) {
      const vins = await getVinwococ();
      handleCsvDownload(vins);
    }
  };

  return (
    <Container fluid className="dashboard">
      <Row>
        <Col lg={12}>
          <h1>Dashboard</h1>
          <p>
            Ultimo invio:{" "}
            {summary?.data_invio ? fromIsoToHuman(summary?.data_invio) : "N.D."}{" "}
            - Data prossimo invio:{" "}
            {summary?.prossimo_invio
              ? fromIsoToHuman(summary?.prossimo_invio)
              : "N.D."}
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          {isFetchingSummary && <Loading />}
          {!isFetchingSummary && (
            <Kpi
              title="coc ricevuti"
              number={summary.total}
              icon={<FontAwesomeIcon icon={faFilePdf} />}
              variant="primary"
            />
          )}
        </Col>
        <Col>
          {isFetchingSummary && <Loading />}
          {!isFetchingSummary && (
            <Kpi
              title="coc conservati"
              number={summary.stored}
              icon={<FontAwesomeIcon icon={faCheck} />}
              variant="success"
              paragraph="Il processo prevede l'invio in conservazione solamente dei COC immatricolati"
            />
          )}
        </Col>
        <Col>
          {isFetchingSummary && <Loading />}
          {!isFetchingSummary && (
            <Kpi
              title="telai senza coc"
              number={summary.missing}
              icon={
                <FontAwesomeIcon
                  icon={isLoading ? faSpinner : faDownload}
                  pulse={isLoading}
                />
              }
              caption="Scarica elenco telai"
              variant="danger"
              onClick={() => handleClick()}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ChartComponent options={options} />
        </Col>
      </Row>
    </Container>
  );
}

export default DashboardPage;
