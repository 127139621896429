import React from "react";
import { Routes, Route } from "react-router-dom";
import {
  ENTITY_BATCHES,
  ENTITY_SEARCH,
  PATH_BATCHES,
  PATH_DASHBOARD,
  PATH_HOME,
  PATH_SEARCH,
  PATH_SUPPORT,
  TYPE_GUEST,
  TYPE_PRIVATE
} from "./utils/constants/constant";
import { DashboardPage } from "./views/DashboardPage/DashboardPage";
import { GenericPage } from "./views/GenericPage/GenericPage";
import { LoginPage } from "./views/LoginPage/LoginPage";
import { AuthRouter } from "./components/AuthRouter";
import { SupportPage } from "./views/SupportPage/SupportPage";

export function Router() {
  return (
    <Routes>
      <Route
        path={PATH_HOME}
        element={(
          <AuthRouter type={TYPE_GUEST}>
            <LoginPage titlePage="COC - Login"/>
          </AuthRouter>
        )}
      />
      <Route
        path={PATH_DASHBOARD}
        element={(
          <AuthRouter type={TYPE_PRIVATE}>
            <DashboardPage titlePage="COC - Dashboard" />
          </AuthRouter>
        )}
      />
      <Route
        path={PATH_BATCHES}
        element={(
          <AuthRouter type={TYPE_PRIVATE}>
            <GenericPage entity={ENTITY_BATCHES} titlePage="COC - Processi" />
          </AuthRouter>
        )}
      />
      <Route
        path={PATH_SEARCH}
        element={(
          <AuthRouter type={TYPE_PRIVATE}>
            <GenericPage entity={ENTITY_SEARCH} titlePage="COC - Cerca" />
          </AuthRouter>
        )}
      />
      <Route
        path={PATH_SUPPORT}
        element={(
          <AuthRouter type={TYPE_PRIVATE}>
            <SupportPage titlePage="COC - Assistenza" />
          </AuthRouter>
        )}
      />
    </Routes>
  );
}

export default Router;
