import { createListenerMiddleware } from "@reduxjs/toolkit";
import {
  ACTION_SET_ACCESS_TOKEN,
  LOCALSTORAGE_ACCESS_TOKEN
} from "../utils/constants/constant";

/**
 * When we receive the token after login,
 * store it into localStorage
 */

const type = `accessTokenSlice/${ACTION_SET_ACCESS_TOKEN}`;

const addAccessTokenMiddleware = createListenerMiddleware();
addAccessTokenMiddleware.startListening({
  type,
  effect: (action) => {
    localStorage.setItem(
      LOCALSTORAGE_ACCESS_TOKEN,
      action.payload.data
    );
  }
});

export default addAccessTokenMiddleware;
