import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import prepareHeaders from "../utils/methods/prepareHeaders";

export const apiSlice = createApi({
  // Reducer Path it's name shown on Redux Tab
  reducerPath: "apiSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
    prepareHeaders: (headers) => {
      prepareHeaders(headers);
    }
  }),
  // With tag type we can invalidate cache
  tagTypes: ["batches", "datas", "download", "search", "stats", "summary", "vinwococ"],
  endpoints: (builder) => ({
    getBatches: builder.query({
      query: () => "/batches",
      providesTags: ["batches"]
    }),
    getDatas: builder.query({
      query: () => "/data",
      providesTags: ["datas"]
    }),
    getSearch: builder.mutation({
      query: (telaio) => ({
        url: `/ifins/search?vin=${telaio}`,
        method: "GET"
      }),
      providesTags: ["search"]
    }),
    getVinwococ: builder.mutation({
      query: () => ({
        url: "/batch/vinwococ",
        method: "GET"
      }),
      providesTags: ["vinwococ"]
    }),
    getDetail: builder.query({
      query: () => "/stats/detail",
      providesTags: ["stats"]
    }),
    getSummary: builder.query({
      query: () => "/stats/summary",
      providesTags: ["summary"]
    }),
    getDownload: builder.mutation({
      query: (uid) => ({
        url: `/download?uid=${uid}`,
        method: "GET"
      }),
      providesTags: ["download"]
    })
  })
});
/**
 * Names export are endpoints: use{endpoint}Query
 */
export const {
  useGetBatchesQuery,
  useGetDatasQuery,
  useGetSearchMutation,
  useGetVinwococMutation,
  useGetDetailQuery,
  useGetSummaryQuery,
  useGetDownloadMutation
} = apiSlice;
