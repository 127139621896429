import React from "react";
import { useSelector } from "react-redux";
import { Sidebar } from "./components/Sidebar";
import { Topbar } from "./components/Topbar";
import { Footer } from "./components/Footer";
import { Router } from "./Router";

function App() {
  const accessToken = useSelector((state) => state.accessTokenSlice.accessToken);
  const idToken = useSelector((state) => state.accessTokenSlice.idToken);

  const getClassName = () => {
    const className="d-flex flex-column";
    if (!accessToken || !idToken) {
      return `${className} content-wrapper-guest`;
    }
    return className;
  };

  return (
    <div id="wrapper">
      {accessToken && idToken && <Sidebar />}
      <div id="content-wrapper" className={getClassName()}>
        <div id="content">
          {accessToken && <Topbar />}
          <Router />
        </div>
        {accessToken && <Footer />}
      </div>
    </div>
  );
}

export default App;
