import React, { useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { Datatable } from "../../components/Datatable";
import { ENTITY_BATCHES, ENTITY_SEARCH } from "../../utils/constants/constant";
import { Loading } from "../../components/Loading";
import {
  useGetBatchesQuery,
  useGetSearchMutation
} from "../../features/apiSlice";
import { Status } from "../../components/Status";
import { FormComponent } from "../../components/FormComponent";
import getRequired from "../../utils/methods/getRequired";
import hasFormErrors from "../../utils/methods/hasFormErrors";
import vinConverter from "../../utils/methods/vinConverter";
import { Download } from "../../components/Download";
import titleTranslate from "../../utils/methods/titleTranslate";

export function GenericPage(props) {
  // Get current entity
  const { entity, titlePage } = props;

  document.title = titlePage;

  /**
   * Local state to save the form
   */
  const [currentItem, setCurrentItem] = useState(null);
  const [disabledForm, setDisabledForm] = useState(true);
  const [vins, setVins] = useState(null);

  const [getSearch, { isLoading: isLoadingSearch }] = useGetSearchMutation();

  /**
   * Set here all fetches.
   * If entity is different, skip it.
   *
   * Pick from useGet[...] three datas
   */
  const {
    data: batches,
    isLoading: isLoadingBatches,
    isFetching: isFetchingBatches
  } = useGetBatchesQuery(undefined, {
    skip: entity !== ENTITY_BATCHES
  });

  const getIcon = (pending) => <Status pending={pending} />;

  const getDownloadComponent = (uid) => <Download uid={uid} />;

  const getColumns = () => {
    if (entity === ENTITY_BATCHES) {
      return [
        {
          name: "Data invio",
          sortable: true,
          sortField: "data_invio",
          selector: (row) => row.data_invio
        },
        {
          name: "COC presenti",
          sortable: true,
          sortField: "ctr",
          selector: (row) => row.ctr
        },
        {
          name: "Status",
          sortable: true,
          sortField: "status",
          selector: (row) => getIcon(row.status)
        },
      ];
    }
    if (entity === ENTITY_SEARCH) {
      return [
        {
          name: "Telaio",
          sortable: true,
          sortField: "vin",
          selector: (row) => row.vin
        },
        {
          name: "Data documento",
          sortable: true,
          sortField: "dataDocumento",
          selector: (row) => row.dataDocumento
        },
        {
          name: "Data invio",
          sortable: true,
          sortField: "dataInvio",
          selector: (row) => row.dataInvio
        },
        {
          name: "Data versamento",
          sortable: true,
          sortField: "dataVersamento",
          selector: (row) => row.dataVersamento
        },
        {
          name: "Data conservazione",
          sortable: true,
          sortField: "dataConservazione",
          selector: (row) => row.dataConservazione
        },
        {
          name: "Download",
          sortable: false,
          selector: (row) => getDownloadComponent(row.uid)
        },
      ];
    }
    return [];
  };

  /**
   * Check if loading or not.
   * We check against current entity and ONE OF loading OR fetching
   *
   */
  const checkIsLoading = () =>
    (entity === ENTITY_BATCHES && (isLoadingBatches || isFetchingBatches)) ||
    (entity === ENTITY_SEARCH && isLoadingSearch);

  /**
   * Return items.
   * We start with an empty array, populated from current entity
   *
   * @returns array
   */
  const getItems = () => {
    let items = [];
    if (entity === ENTITY_BATCHES && batches && batches.length > 0) {
      // Copy all array into items
      items = [...batches];
    }
    if (entity === ENTITY_SEARCH && vins) {
      if (!vins.data) {
        return [];

      }
      // Copy all array into items
      items = vinConverter(vins.data);
    }
    return items;
  };

  const getTitle = () => {
    let title = "";
    if (entity === ENTITY_BATCHES) {
      title = "Processi";
    }
    if (entity === ENTITY_SEARCH) {
      title = "Cerca";
    }
    return title;
  };

  /**
   * I campi dell'entità search.
   */
  const searchFields = [
    {
      id: 1,
      name: "telaio",
      label: "telaio",
      type: "text",
      required: true
    },
  ];

  const handleChange = (e) => {
    // Destrutturo l'oggetto in arrivo
    const { id, value } = e.target;
    // Preparo un oggetto temporaneo
    const tempItem = {
      ...currentItem,
      [id]: value
    };
    // Lo salvo
    setCurrentItem(tempItem);

    // Prendo solo i campi obbligatori
    const required = getRequired(searchFields);

    /**
     * Passo alla funzione l'oggetto temporaneo e i campi obbligatori
     */
    setDisabledForm(true);
    if (!hasFormErrors(tempItem, required)) {
      setDisabledForm(false);
    }
  };

  const handleClick = async () => {
    const result = await getSearch(currentItem.telaio);
    setVins(result);
  };

  return (
    <Container fluid>
      <Row>
        <Col lg={12}>
          <h1>{getTitle()}</h1>
        </Col>
        {entity === ENTITY_SEARCH && (
          <Col lg={4}>
            <Card>
              <Card.Header>
                <Card.Title>Cerca</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form className="form-coc">
                  <Row>
                    {searchFields.map((item) => (
                      <Col key={item.id} lg={12}>
                        <FormComponent
                          field={item}
                          onChange={handleChange}
                          currentItem={currentItem}
                        />
                      </Col>
                    ))}
                    <Col>
                      {isLoadingSearch ? (
                        ""
                      ) : (
                        <Button onClick={handleClick} disabled={disabledForm}>
                          Cerca
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        )}
        <Col lg={entity === ENTITY_SEARCH ? 8 : 12}>
          <Card>
            <Card.Header>
              <Card.Title>{titleTranslate(entity)}</Card.Title>
            </Card.Header>
            <Card.Body>
              {checkIsLoading() ? (
                <Loading />
              ) : (
                <Datatable
                  columns={getColumns()}
                  data={getItems()}
                  pagination
                />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default GenericPage;
