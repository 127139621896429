import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

export const loginCognitoUser = (data) => {
  const authenticationData = {
    Username: data.username,
    Password: data.password
  };
  const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
    authenticationData
  );

  const poolData = {
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID
  };

  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

  const userData = {
    Username: data.username,
    Pool: userPool
  };
  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

  const payload = {
    cognitoUser,
    authenticationDetails
  };
  return payload;
};

/**
 *
 * Extract from a AWS promise result the accessToken OR idToken
 *
 * @param {*} promisedResult
 * @returns
 */
export const extractTokenFromPromisedAnswerResult = (
  promisedResult,
  tokenType
) => {
  if (
    promisedResult
    && promisedResult[tokenType]
    && promisedResult[tokenType].jwtToken
  ) {
    return promisedResult[tokenType].jwtToken;
  }
  return null;
};
